import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import "./styles.css";
import { mountOMeet } from "./helpers/mount-apps.helpers";
import { calculateRefreshTime } from "./utils/auth/auth.service";

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    if (name === "@single-spa/welcome") {
      return import(/* webpackIgnore: true */ name);
    }
    // If the app is @onfusion/o-meet, load it in an iframe
    if (name === "@onfusion/o-meet") {
      return mountOMeet();
    }

    return System.import(/* webpackIgnore: true */ name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

layoutEngine.activate();
calculateRefreshTime();

start();
