import { O_CONNECT_HOST_URL } from "../envs.constants";

export const handleIframeMessage = (event: MessageEvent, env: string) => {
  const iFrameUrl = O_CONNECT_HOST_URL[env];

  if (event.origin !== iFrameUrl) {
    return; // Ignore messages from unauthorized sources
  }

  const message = event.data as { type: string; url?: string };
  if (message.type === "CHANGE_PARENT_URL" && message.url) {
    window.location.replace(message.url);
  }
};

export const createIframe = (env: string) => {
  const newIFrame = document.createElement("iframe");
  newIFrame.src = O_CONNECT_HOST_URL[env];
  newIFrame.width = "100%";
  newIFrame.height = "100%";
  newIFrame.allow = "clipboard-read; clipboard-write ;  web-share";
  newIFrame.style.border = "none";
  newIFrame.id = "iframe-o-meet";
  return newIFrame;
};
