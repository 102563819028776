import { EDomain, EEnvironment } from "../types";

export const getCurrentEnv = () => {
  const currentPath = window.location.origin;
  if (currentPath.includes("localhost")) {
    return EEnvironment.local;
  }
  if (currentPath.includes("-dev")) {
    return EEnvironment.dev;
  }
  if (currentPath.includes("-qa")) {
    return EEnvironment.qa;
  }
  if (currentPath.includes("-staging")) {
    return EEnvironment.staging;
  }
  return EEnvironment.prod;
};


export const getDomain = () => {
  const env = getCurrentEnv();

  if(env == "prod")
    return EDomain.prod;

  return EDomain.others;
};