import { registerApplication, start } from "single-spa";
import { constructApplications, constructLayoutEngine, constructRoutes, } from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
    routes,
    loadApp({name}) {
        if (name == '@single-spa/welcome')
            return import(/* webpackIgnore: true */ name);

        return System.import(/* webpackIgnore: true */ name);
    },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
