import { getCookie, setLoginDateTimeInCookie } from "../cookie-utils";
import { url } from "../../types";
import { getCurrentEnv } from "../environment-utils";

export function getLoginDateTime(): Date | null {
    const loginDateTime = getCookie('loginDateTime');
    return loginDateTime ? new Date(loginDateTime) : null;
}

async function refreshTokenApi() {
    try {
        console.log('refresh')
        const env = getCurrentEnv();
        const response = await fetch(`${url[env]}/sso/api/user/token/refresh`, {
            method: 'POST',
            credentials: 'include',
        });
        if (response.ok) {
            const data = await response.json();
            console.log('Token refreshed:', data);
            // Update login time if successful
            setLoginDateTimeInCookie();
            await calculateRefreshTime();
        } else {
            console.error('Failed to refresh token');
        }
    } catch (error) {
        console.error('Error during token refresh:', error);
    }
}

// Function to calculate the time until refresh and trigger refresh token
export async function calculateRefreshTime() {
    const loginDateTime = getLoginDateTime();
    if (loginDateTime) {
        const now = new Date();
        const tokenAgeInMillis = now.getTime() - loginDateTime.getTime();
        const sixHoursInMillis = 6 * 60 * 60 * 1000; // 6 hours in milliseconds

        if (tokenAgeInMillis >= sixHoursInMillis) {
            // If the token is already older than 6 hours, refresh immediately
            await refreshTokenApi();
        } else {
            // Calculate the time remaining to reach 6 hours
            const timeRemaining = sixHoursInMillis - tokenAgeInMillis;
            console.log(`Time remaining to refresh token: ${timeRemaining / 1000 / 60} minutes`);

            // Set a timeout to refresh the token after the remaining time
            setTimeout(async () => {
                console.log('Refreshing token now...');
                await refreshTokenApi();
            }, timeRemaining);  // Trigger refresh when 6 hours is reached
        }
    } else {
        console.log('No login date found, cannot calculate refresh time.');
    }
}