import { createIframe, handleIframeMessage } from "../utils/iframe-utils";
import { getCurrentEnv } from "../utils/environment-utils";
import { Application } from "single-spa";

export function mountOMeet(): Promise<Application>{
  const env = getCurrentEnv();

  return new Promise((resolve, reject) => {
    let iframe = null; // Track the latest iframe

    resolve({
      bootstrap: () => Promise.resolve(),
      mount: () => {
        iframe = createIframe(env); // Create iframe dynamically

        document.body.appendChild(iframe);
        iframe.onload = () => {
          // Post message to notify the iframe has been mounted
          const iframeOrigin = new URL(iframe.src).origin;
          const checkIframeReady = setInterval(() => {
            if (iframe.contentWindow) {
              iframe.contentWindow.postMessage(
                { type: "MEET_MOUNTED" },
                iframeOrigin
              );
              clearInterval(checkIframeReady); // Stop checking once message is sent
            }
          }, 100); // Check every 100ms until contentWindow is available

          // Ensure that we are listening to messages from the current iframe
          window.addEventListener("message", (event) =>
            handleIframeMessage(event, env)
          );
        };
        return Promise.resolve();
      },
      unmount: () => {
        // When unmounting, we only clean up if the iframe matches
        if (iframe) {
          const iframeOrigin = new URL(iframe.src).origin;
          iframe.contentWindow?.postMessage(
            { type: "MEET_UNMOUNTED" },
            iframeOrigin
          );

          // Clean up iframe from DOM
          iframe.parentElement?.removeChild(iframe);
          iframe = null; // Clear reference

          // Clean up message event listener
          window.addEventListener("message", (event) =>
            handleIframeMessage(event, env)
          );
        }
        return Promise.resolve();
      },
    });

    iframe.onerror = (e) => reject(e);
  });
}
