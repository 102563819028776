export enum EEnvironment {
    local = "local",
    dev = "dev",
    staging = "staging",
    qa = "qa",
    prod = "prod",
}

export enum EDomain {
    prod = "onfusion.net",
    others = "onfusiondemo.cc"
}

export enum url {
    dev = 'https://oes-api-dev.onfusiondemo.cc',
    qa = 'https://oes-api-qa.onfusiondemo.cc',
    stg = 'https://oes-api-stg.onfusiondemo.cc',
    prod = 'https://eco.onfusion.net'
}