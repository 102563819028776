import { getDomain } from "./environment-utils";

export function getCookie(name: string): string | null {
    const cookieArr = document.cookie.split(';');

    for (let i = 0; i < cookieArr.length; i++) {
        let cookie = cookieArr[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }

    return null;
}

 export function setLoginDateTimeInCookie() {
     const loginDateTime =  new Date().toISOString();
     document.cookie = `loginDateTime=${loginDateTime}; path=/; domain=.${getDomain()}; SameSite=None; Secure`;
 }